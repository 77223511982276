import {Helmet} from "react-helmet-async";
import React from 'react';

import perfect from '../media/books/from.png';
import how from '../media/books/How I did it.png';
import hts from "../media/books/hts.png";
import why from "../media/books/why most students fail.png";
import wjh from "../media/books/wjh.png";


// const message = "https://wa.me/message/WU3R7WHN5WYOE1";


export default function Books(){
    const height = 240;
    const width = 180;
    return (
        <div>
            <Helmet>
                <title>STudy STar: Book Page</title>
                <meta 
                    name='description' 
                    content="Self help and Improvement books just for you..."
                    href="/books"
                />
                <link rel="canonical" href='/books'/>
            </Helmet>
            
            <br/>
            <h1 style={styles.h1}>BOOKS PAGE</h1>
            <div className='container' style={{textAlign: 'center'}}>
                <div className="row" style={{justifyContent: 'center'}}>
                    <div style={styles.border} className="col-md-3.5">
                        <h3 style={styles.tit}>From 21% to 100%</h3>
                        <p style={styles.image}><a href='https://drive.google.com/uc?id=10eLy5_cG_esYkGkzP6z5oav3cy_CRot8&export=download'><img style={styles.img} src={perfect} height={height} width={width} alt="From 21 to 100" className="products"/></a></p>
                        <p style={styles.para}>
                            It took me 4.5 weeks or 31 days to transition from an F student to an A+
                            student. I was the definition of unorganized, dull and playful.
                            I never took anything seriously and I laughed at the people who knew less
                            than I did. I was selfish and greedy and I wanted everything for myself. I
                            had no goals, no plan and no action, I couldn't even read one definition.
                            After seeing that my life was a huge mess, I did something that every
                            great person does and I transformed myself. I first changed my friends,
                            then I changed my music, then I changed my movies and I changed
                            almost every emotional and mental part of myself.
                            My average academic performance in grade 8 was 21% and in grade 12 it
                            increased to 95%+. What made me change from an F student (21%) to an
                            A+ student (100%)? That's exactly what I'll be teaching you in this book,
                            so keep reading and don‟t skip any part until you reach the end.

                        </p>
                        <p style={{textAlign: 'center', }}><button className='btn btn-success'><a href='https://drive.google.com/uc?id=10eLy5_cG_esYkGkzP6z5oav3cy_CRot8&export=download' style={{textDecoration: 'none', color: 'white'}}>Download Now</a></button></p>
                        <p style={{textAlign: 'right', marginRight: 15, fontWeight: 'bolder'}}><small>Price: <x style={styles.cancelled}>19.99 ZMW</x><br/><x style={styles.new}>FREE</x></small></p>
                    </div>
                    <div style={styles.border} className="col-md-3">
                        <h3 style={styles.tit}>How to Study Effectively and Efficiently</h3>
                        <p style={styles.image}><a href='https://drive.google.com/uc?id=1M9VUTT6dr3e_af98L2qxHs6VM91pSYKc&export=download'><img style={styles.img} src={hts} height={height} width={width} alt="How to Study" className="products"/></a></p>
                        <p style={styles.para}>
                        Understand that there is a big difference between being effective and being efficient when it comes to studying. Someone
                        can be very efficient but not be effective,
                        in the same way someone can be very effective but not be efficient. What’s the difference? Effectiveness is producing great
                        results while efficiency is studying for
                        long hours.
                        If you’re effective you can produce very great results without
                        even spending a lot of time studying. On the other hand an efficient person can spend a lot of time studying but still 
                        produce very poor results.
                        So what’s the best thing to do? If you want to be the best student you need to be effective as well as efficient. In this 
                        book I will give you the ways of
                        being efficient and effective and I guarantee you are going to be at least one of the best students in your class.
                        </p>
                        <p style={{textAlign: 'center'}}><button className='btn btn-success'><a href='https://drive.google.com/uc?id=1M9VUTT6dr3e_af98L2qxHs6VM91pSYKc&export=download' style={{textDecoration: 'none', color: 'white'}}>Download Now</a></button></p>
                        <p style={{textAlign: 'right', marginRight: 15, fontWeight: 'bolder'}}><small>Price: <x style={styles.cancelled}>14.99 ZMW</x><br/><x style={styles.new}>FREE</x></small></p>
                    </div>
                    <div style={styles.border} className="col-md-4">
                        <h3 style={styles.tit}>Why most students fail</h3>
                        <p style={styles.image}><a href='https://drive.google.com/uc?id=1AHED4cvLAuLXDMqYkJmZuKUWkmF0Oy6q&export=download'><img style={styles.img} src={why} height={height} width={width} alt="Why most student's fail" className="products"/></a></p>
                        <p style={styles.para}>
                            No student wants to fail I guarantee, but what do most students do? The
                            exact opposite of what they want, they fail. Why does this happen? Why
                            does what you don't want to happen happen instead of what you want? The
                            answers are very simple, because it’s not just one and you need to know them
                            all. Know that it’s all on you. I'll be teaching you what to do in this book,
                            when to do them is completely your choice.
                            First of all, you should know that failure has everything to do with
                            success and success has everything to do with failure. They go together,
                            without failing you're not growing and therefore, your chances of success
                            are relatively low.
                        </p>
                        <p style={{textAlign: 'center'}}><button className='btn btn-success'><a href='https://drive.google.com/uc?id=1AHED4cvLAuLXDMqYkJmZuKUWkmF0Oy6q&export=download' style={{textDecoration: 'none', color: 'white'}}>Download Now</a></button></p>
                        <p style={{textAlign: 'right', marginRight: 15, fontWeight: 'bolder'}}><small>Price: <x style={styles.cancelled}>7.99 ZMW</x><br/><x style={styles.new}>FREE</x></small></p>
                    </div>
                    <div style={styles.border} className="col-md-3.5">
                        <h3 style={styles.tit}>What just happened</h3>
                        <p style={styles.image}><a href='https://drive.google.com/uc?id=1zk1S9pAkpjVRGDKmr3pFdL076TT-qxd6&export=download'><img style={styles.img} src={wjh} height={height} width={width} alt="What Just Happened" className="products"/></a></p>
                        <p style={styles.para}>
                            As I was seated in my bedroom on this particular
                            Wednesday, I told myself that I was going to go on an adventure
                            to a place I had never gone before. The time was 13:06 hours and
                            the sun was very hot, so I decided to wait and go at 17:00 hours.
                            I took a cold shower, got dressed and I was ready to go. But
                            because of the heat, I started watching TV instead. I was
                            watching a Zee World block buster action movie by the name of
                            ‘Jai Ho!’ at 15:00 and at 16:54 hours, I was still very glued to the
                            couch. But because I made a commitment that I was going to go
                            at 17:00 hours, I stood up and put on my old black slippers.
                        </p>
                        <p style={{textAlign: 'center', textDecoration: 'none'}}><button className='btn btn-success'><a href='https://drive.google.com/uc?id=1zk1S9pAkpjVRGDKmr3pFdL076TT-qxd6&export=download' style={{textDecoration: 'none', color: 'white'}}>Download Now</a></button></p>
                        <p style={{textAlign: 'right', marginRight: 15, fontWeight: 'bolder'}}><small>Price: <x style={styles.cancelled}>4.99 ZMW</x><br/><x style={styles.new}>FREE</x></small></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = ({
    border: {
        border: "1px dotted black", 
        // margin: "1em",
        borderRadius: 10,
        marginBottom: 10,
        marginTop: 10,
        marginLeft: 10,
        marginRight: 10,
    },
    cancelled: {
        textDecoration: 'line-through',
        color: 'red'
    },
    new: {
        fontWeight: 'bolder',
        color: 'rgb(12, 200, 150)',
    },
    image: {
        paddingTop: 10,
        paddingBottom: 10,
        textAlign: 'center',
        
    },
    para: {
        paddingBottom: 15,
        fontFamily: 'arial',
        textIndent: 20,
        textAlign: 'left'
    },
    tit: {
        fontFamily: '"Castoro Titling", serif',
        fontWeight: 400,
        fontStyle: 'normal',
        marginTop: 10,
        textAlign: 'center'
    },
    h1: {
        textAlign: 'center', 
        textDecoration: 'underline', 
        fontFamily: "tahoma", 
        textDecorationColor: 'gold',
        fontWeight: 900,
    },
    img: {
        borderRadius: 9
    }
    
})