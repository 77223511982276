import React from 'react'

export default function Pathology() {
  return (
    <div><br/>
        <h3 style={{textAlign:'center', textDecoration: 'underline'}}>PATHOLOGY</h3>
        <h4 style={{textAlign:'center'}}>Past papers</h4>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Year by Year</h5>
        <div className='container'>
            <div style={styles.year}>2023</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1svnCw5EkBLooiZr6Wgs0pN2Q0jj2T791&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1W87j9Gw_tdNwBkQY0287NJHEUkHiRDFm&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1EJEhAtrEbrInqUl9q7-3X2kbopDPVLat&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2022</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1I4O2dnl4nL3BlWBldYZJsmy8jqcFBs3r&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2021</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1ni21RF_UEvxLT2U-lZuOm6v0gos5LRpY&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2020</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1iUC7D8svn-0mqZpw8Et5vcIwR_QAgq6o&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1zhEpWkqjE7XuYNOYanWQjVtMmxuam1g9&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=19dSzUyR36UfzHQppoY5NP-iy0gOFi41k&export=download' style={styles.testText}>Sessional</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=19uJacE59THA0D98jKfogYnw2Wq84Wy_V&export=download' style={styles.testText}>Sessional (With Answers)</a></div>
            </div>
            <div style={styles.year}>2019</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1tyBhleikG75FEl3Ugs7A5zkCSgs1GX6q&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2018</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1HKQ3up2vwi3OYo3R5rtZtFBjJEquPP01&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2017</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1YH8O_EkKS6Bzo22DlEkL9RXN_pZhAHWj&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1WXDN7WQewrERkcsP-fBeCYdKANOSmmqq&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1Pjmuh8RkjKuiFUZmlrUYqyByMALsL3h-&export=download' style={styles.testText}>Test 2 (Practical)</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2016</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1ztBabWEREpeGyq4be8oSvVaiVmU13O27&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1vbm9-BrPUz4VvMBfXLbEvkm94OrdyWNq&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2015</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1u64I9YcPctwiMxruxu0gYwxKdNF1QGTc&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2014</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1P5y0y4iggKIDf6iKuHK97UaJt5F1NiGR&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2013</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1hCpRycT8FLadIfgCmHIiAxM17COXTPJG&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1LdZqDuVFhN2IY4qd_A97Yj_rNbWVOGSw&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2012</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=10aHYwr1nBupr4E1O2-zA_wkKsNSj9cqw&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1x6KSdf5YwuvcK8c1F4s1rfEIMElP7C6V&export=download' style={styles.testText}>Sessional</a></div>
            </div>
        </div>

        <h4 style={{textAlign:'center'}}>Notes</h4>
        <h4 style={{textAlign:'center', textDecoration: 'underline'}}>Topical Revision Questions</h4><br/>
        <p style={styles.linkCon}><a href='/introduction_to_pathology' style={styles.link}>Introduction to Pathology</a><br/></p>
    </div>
  )
}


const styles = {
    test: {
        padding: 6,
        margin: 8,
        textAlign: 'center'
    },
    testText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(0, 162, 232)',
        color: 'white',
        borderRadius: 2
    },
    linkCon: {
        textAlign: 'center',
    },
    link: {
        padding: 5,
        border: "1px solid black",
        borderRadius: 5,
        fontWeight: 'bolder',
        textDecoration: 'none',
        color: 'blue'
    },
    topicText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(255, 120, 155)',
    },
    year: {
        fontWeight: 'bolder', 
        fontSize: 25,
        textAlign: 'center'
    }
}