import React, {useState} from 'react';

import { FaCheck } from "react-icons/fa";
import { ImCross } from 'react-icons/im';


export default function IntroductionToMathematics(){
    const [ showResults, setShowResults ] = useState(false)
    const clicked = () => setShowResults(true)
    const [ ans1, setAns1 ] = useState('Pick an answer')
    const correctAnswer1 = 'A';
    const [ans2, setAns2] = useState('Pick an answer')
    const correctAnswer2 = 'B';
    const [ans3, setAns3] = useState('Pick an answer')
    const correctAnswer3 = 'C';
    const [ans4, setAns4] = useState('Pick an answer')
    const correctAnswer4 = 'C';
    const [ans5, setAns5] = useState('Pick an answer')
    const correctAnswer5 = 'B';
    const [ans6, setAns6] = useState('Pick an answer')
    const correctAnswer6 = 'A';
    const [ans7, setAns7] = useState('Pick an answer')
    const correctAnswer7 = 'B';
    const [ans8, setAns8] = useState('Pick an answer')
    const correctAnswer8 = 'B';
    const [ans9, setAns9] = useState('Pick an answer')
    const correctAnswer9 = 'A';
    const [ans10, setAns10] = useState('Pick an answer')
    const correctAnswer10 = 'C';

    let c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, total

    return (
        
        <div><br/>
            <div className='container'>
                <p style={{fontSize: 20, textAlign: 'center', textDecoration: 'underline'}}>Introduction to Mathematics</p>
                <p>
                    <ol>
                        <form>
                            <li>
                                - x + = 
                            </li>
                                <ol type='a'>
                                    <li>-</li>
                                    <li>+</li>
                                    <li>None of the above</li>
                                </ol>
                                <select
                                    value={ans1}
                                    onChange={(e) => setAns1(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans1 === correctAnswer1 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c1 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c1 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            

                            <br/>
                            <li>
                                -7 x 3 = 
                            </li>
                                <ol type='a'>
                                    <li>+21</li>
                                    <li>-21</li>
                                    <li>-(-21)</li>
                                </ol>
                                <select
                                    value={ans2}
                                    onChange={(e) => setAns2(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans2 === correctAnswer2 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c2 = 1} mark ]</div>: <div style={{color: 'red'}}> Wrong <ImCross/> [{c2 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                -4 + (-12) = 
                            </li>
                                <ol type='a'>
                                    <li>-8</li>
                                    <li>16</li>
                                    <li>-16</li>
                                </ol>
                                <select
                                    value={ans3}
                                    onChange={(e) => setAns3(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans3 === correctAnswer3 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c3 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c3 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                -2 - (-4)
                            </li>
                                <ol type='a'>
                                    <li>-6</li>
                                    <li>-2</li>
                                    <li>2</li>
                                </ol>
                                <select
                                    value={ans4}
                                    onChange={(e) => setAns4(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans4 === correctAnswer4 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c4 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c4 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                <math>
                                    <mfrac>
                                        <mrow>
                                            <mi>4</mi>
                                        </mrow>
                                        <mrow>
                                            <mi>5</mi>
                                        </mrow>
                                    </mfrac>
                                </math>
                                 <b style={{fontSize: 20}}> + </b>
                                <math>
                                    <mfrac>
                                        <mrow>
                                            <mi>1</mi>
                                        </mrow>
                                        <mrow>
                                            <mi>6</mi>
                                        </mrow>
                                    </mfrac>
                                </math>
                                <b> =</b>
                            </li><br/>
                                <ol type='a'>
                                    <li>
                                        <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>5</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>11</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                    </li><br/>
                                    <li>
                                        <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>29</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>30</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                    </li><br/>
                                    <li>
                                        <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>10</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>6</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                    </li><br/>
                                </ol>
                                <select
                                    value={ans5}
                                    onChange={(e) => setAns5(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans5 === correctAnswer5 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c5 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c5 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                <math>
                                    <mfrac>
                                        <mrow>
                                            <mi>1</mi>
                                        </mrow>
                                        <mrow>
                                            <mi>4</mi>
                                        </mrow>
                                    </mfrac>
                                </math>
                                 <b style={{fontSize: 20}}> - </b>
                                <math>
                                    <mfrac>
                                        <mrow>
                                            <mi>1</mi>
                                        </mrow>
                                        <mrow>
                                            <mi>5</mi>
                                        </mrow>
                                    </mfrac>
                                </math>
                                <b> =</b>
                            </li><br/>
                                <ol type='a'>
                                    <li>
                                        <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>1</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>20</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                    </li><br/>
                                    <li>
                                        <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>-1</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>20</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                    </li><br/>
                                    <li>
                                        <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>0</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>-1</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                    </li><br/>
                                </ol>
                                <select
                                    value={ans6}
                                    onChange={(e) => setAns6(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans6 === correctAnswer6 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c6 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c6 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                <math>
                                    <mfrac>
                                        <mrow>
                                            <mi>2</mi>
                                        </mrow>
                                        <mrow>
                                            <mi>3</mi>
                                        </mrow>
                                    </mfrac>
                                </math>
                                 <b style={{fontSize: 20}}> x </b>
                                <math>
                                    <mfrac>
                                        <mrow>
                                            <mi>2</mi>
                                        </mrow>
                                        <mrow>
                                            <mi>5</mi>
                                        </mrow>
                                    </mfrac>
                                </math>
                                <b> =</b>
                            </li><br/>
                                <ol type='a'>
                                    <li>
                                        <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>5</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>3</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                    </li><br/>
                                    <li>
                                        <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>4</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>15</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                    </li><br/>
                                    <li>
                                        <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>4</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>5</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                    </li><br/>
                                </ol>
                                <select
                                    value={ans7}
                                    onChange={(e) => setAns7(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans7 === correctAnswer7 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c7 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c7 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                <math>
                                    <mfrac>
                                        <mrow>
                                            <mi>1</mi>
                                        </mrow>
                                        <mrow>
                                            <mi>4</mi>
                                        </mrow>
                                    </mfrac>
                                </math>
                                 <b style={{fontSize: 20}}> ÷ </b>
                                <math>
                                    <mfrac>
                                        <mrow>
                                            <mi>2</mi>
                                        </mrow>
                                        <mrow>
                                            <mi>3</mi>
                                        </mrow>
                                    </mfrac>
                                </math>
                                <b> =</b>
                                
                            </li><br/>
                                <ol type='a'>
                                    <li>
                                        <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>2</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>12</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                        </li>
                                        <br/>
                                            <li>
                                                <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>3</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>8</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                        </li>
                                        <br/>
                                            <li>
                                                <math>
                                            <mfrac>
                                                <mrow>
                                                    <mi>1</mi>
                                                </mrow>
                                                <mrow>
                                                    <mi>6</mi>
                                                </mrow>
                                            </mfrac>
                                        </math>
                                    </li>
                                    <br/>
                                </ol>
                                <select
                                    value={ans8}
                                    onChange={(e) => setAns8(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans8 === correctAnswer8 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c8 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c8 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>  
                            <li>
                                3.124 + 11.3 = 
                            </li>
                                <ol type='a'>
                                    <li>14.424</li>
                                    <li>323.7</li>
                                    <li>42.54</li>
                                </ol>
                                <select
                                    value={ans9}
                                    onChange={(e) => setAns9(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans9 === correctAnswer9 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c9 = 1} mark ]</div> : <div style={{color: 'red'}}>Wrong <ImCross/> [{c9 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                            <br/>
                                <li>
                                    2.36 x 2.1
                                </li>
                                <ol type='a'>
                                    <li>495.6</li>
                                    <li>49.56</li>
                                    <li>4.956</li>
                                </ol>
                                <select
                                    value={ans10}
                                    onChange={(e) => setAns10(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans10 === correctAnswer10 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c10 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c10 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                        </form>
                        
                    </ol>
                    <div style={{textAlign: 'center'}}>
                        <superButton style={{ 
                            backgroundColor: 'rgb(12, 168, 140)',
                            padding: 8,
                            borderRadius: 4,
                            cursor: 'pointer'
                        }} onClick={clicked}>Show Results</superButton>
                        {
                            showResults? (
                            <div><br/>
                                <div> You got: {c1 + c2 + c3 + c4 + c5 + c6 + c7 + c8 + c9 + c10}/10 </div>
                                <p> Which is {total = (c1 + c2 + c3 + c4 + c5 + c6 + c7 + c8 + c9 + c10)/10*100} % </p>
                                {total < 40? <b style={{color: 'rgb(255, 0, 0)', fontSize: 20}}> D: Fail </b>: total < 50 && total >= 40? <b style={{color: 'rgb(252, 63, 7)', fontSize: 22}}> D+: Bare Fail </b>: total < 56 && total >= 50 ? <b style={{color: 'rgb(252, 185, 7)', fontSize: 24}}> C: Bare Pass </b>: total < 62 && total >= 56 ? <b style={{color: 'rgb(240, 253, 6)', fontSize: 26}}> C+: Definite Pass </b>: total < 68 && total >= 62 ? <b style={{color: 'rgb(154, 254, 5)', fontSize: 28}}> B: Very Satisfactory </b>: total < 75 && total >= 68 ? <b style={{color: 'rgb(68, 225, 0)', fontSize: 30}}> B+: Meritorious </b>: total < 86 && total >= 75 ? <b style={{color: 'rgb(0,225,12)', fontSize: 32}}> A: Distinction </b>: total <= 100 && total >= 86 ? <b style={{color: 'rgb(1, 203, 67)', fontSize: 34}}> A+: First Distinction </b>: <div>Error</div>}
                            </div>): null
                        }
                    </div>
                </p>
            </div>
        </div>
    )
}

// const styles = ({
//     ana: {
//         width: '90%'
//     },
//     p: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 14,
//         marginBottom: 14,
//         listStyle: 'none',
        
//     },
//     li: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 20,
//         marginBottom: 20,
        
//     },
//     h1: {
//         textAlign: 'center', 
//         textDecoration: 'underline', 
//         fontFamily: "tahoma", 
//         textDecorationColor: 'rgb(16, 167, 200)',
//         fontWeight: 900,
//     }
// })