import {Helmet} from 'react-helmet-async';

import mindsetChange from '../media/podcasts/MindsetChange.mp3'


// Imports 
import React from 'react';

//Paid tutorials
// import eco from '../media/practice/eco.png'
// import repropa from '../media/practice/reproduction answers.png'
// import simultaneous from '../media/tutorials/sr/mat/simultaneous equations.png'
import hol from '../media/tutorials/sr/bio/holozoic nutrition.png';

import nar from '../media/tutorials/sr/eng/narrative composition.png';

import alg from '../media/tutorials/sr/mat/algebra.png';

import forc from '../media/tutorials/sr/phy/forces.png';

import aci from '../media/tutorials/sr/che/acids, bases and salts.png';

import celp from '../media/Cell Physiology.pdf';

import how from '../media/books/How I did it.png';

import mec from "../media/tutorials/sr/phy/mechanics.png";
import trm from "../media/tutorials/sr/bio/transport in man.png";
import ato from "../media/tutorials/sr/che/atomic structure.png";
import coms from "../media/tutorials/sr/eng/comprehension.png";
import intro from "../media/tutorials/sr/mat/introduction to mathematics.png";
// //End of Image Imports



export default function Home(){
    const height = 240;
    const width = 180;
    // const click = () => {
    //     alert("You have just clicked on a purchased Tutorial, click OK to continue to WhatsApp")
    // }
    return (
        <div>
            <Helmet>
                <title>STudy STar: Home Page</title>
                <meta 
                    name='description' 
                    content="STudy STar: A Top Student's secret tool"
                    href="/"
                />
                <link rel="canonical" href='/'/>
            </Helmet>
            <br/>
            <h1 style={styles.h1}>HOME PAGE</h1>
            <div style={styles.body}>
                <div style={styles.subTitle}>
                    </div>
                    <p style={{textAlign: 'center', fontSize: 16}}>
                        "A man's character is not judged after he celebrates a victory but by what he does when his back is against the wall." - John Cena.
                    </p>
                        <div className='container' style={{fontSize: 16, color: 'black', textAlign: 'center', textDecoration: 'underline'}}>
                            <h4 style={{fontWeight: 'bolder'}}>New This Week</h4></div>
                        <div style={{margin: 10}}>
                            <p style={{textAlign: 'center', fontWeight: 'bolder'}}>

                                    <p style={styles.linkCon}><a href='/introduction_to_pathology' style={styles.link}>Introduction to Pathology</a><br/></p>
                                    <p style={styles.linkCon}><a href='/introduction_to_physiology' style={styles.link}>Introduction to Physiology</a><br/></p>
                            </p>
                        </div>
                            
                <div className='container'>
                <div className='row'>
                    <div className='col-md-7' style={{textAlign: 'center'}}>
                    <h4 style={styles.subTitle}>Other New Items</h4>
                    <a href={celp} style={styles.a}>Cell Physiology</a>

                    


                    {/* <h4 style={styles.subTitle}>TOP Tutorials</h4>
                    <p style={{textAlign: 'center'}}>The best tutorials</p>
                        <a href='https://drive.google.com/uc?id=1sSzdIrjVyQHr2LLEP2xjgmDxDOXGIvT6&export=download' style={styles.a}><img src={hol} height={187.04} width={132.32} alt="Holozoic Nutrition" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1RuwSCSwQJ4wryoeM0z2vDFQ_jjhzu1pw&export=download' style={styles.a}><img src={nar} height={187.04} width={132.32} alt="Narrative Composition" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1hVD6VN5o86X_8zJItSU4KysWjAoWPiho&export=download' style={styles.a}><img src={alg} height={187.04} width={132.32} alt="Algebra" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=10iwBl2Z9orXFty39UFCOZPSwkaakDL7s&export=download' style={styles.a}><img src={forc} height={187.04} width={132.32} alt="Forces" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1Y2_P9h9nfLVRll8bzksaj6fn9IdhBPPy&export=download' style={styles.a}><img src={aci} height={187.04} width={132.32} alt="Basic Calculations" className='products' style={styles.img}/></a> */}

                    </div>
                    <div className='col-md-5' style={{textAlign: 'center'}}>
                    <h4 style={styles.subTitle}>MOST DOWNLOADED</h4>
                    <p>Here are top 5 most downloaded tutorials</p>
                        <a href='https://drive.google.com/uc?id=1sSzdIrjVyQHr2LLEP2xjgmDxDOXGIvT6&export=download' style={styles.a}><img src={trm} height={187.04} width={132.32} alt="Transport in Man" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1RuwSCSwQJ4wryoeM0z2vDFQ_jjhzu1pw&export=download' style={styles.a}><img src={ato} height={187.04} width={132.32} alt="Atomic Structure" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1hVD6VN5o86X_8zJItSU4KysWjAoWPiho&export=download' style={styles.a}><img src={coms} height={187.04} width={132.32} alt="Comprehension" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=10iwBl2Z9orXFty39UFCOZPSwkaakDL7s&export=download' style={styles.a}><img src={intro} height={187.04} width={132.32} alt="Introduction to Mathematics" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1Y2_P9h9nfLVRll8bzksaj6fn9IdhBPPy&export=download' style={styles.a}><img src={mec} height={187.04} width={132.32} alt="Mechanics" className='products' style={styles.img}/></a>
                    </div>
                    </div>
                </div>    
                </div>
            </div>
    )
}

const styles = ({
    target: {
        fontFamily: 'tahoma',
        textAlign: 'center',
        fontSize: 18,
    },
    body: {
        fontFamily: 'arial',
        fontSize: 15,
        margin: 10
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 20,
        marginTop: 15, 
        color: 'rgb(40, 150, 200)',
        fontWeight: 'bold'
    },
    items: {
        listStyle: 'none'
    },
    img: {
        marginTop: 10, 
        marginBottom: 10,
        borderRadius: 5
    },
    par: {
        margin: 5,
        fontSize: 16
    },
    a: {
        marginLeft: 1,
        marginRight: 1,
    },
    linkCon: {
        border: "1px solid blue",
        borderRadius: 4,
        padding: 5,
        margin: 5
    },
    border: {
        border: "1px dotted black", 
        margin: "1em",
        borderRadius: 10,
        textAlign: 'center'
    },
    image: {
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 5
    },
    para: {
        paddingBottom: 15,
        fontSize: 18,
        fontFamily: 'times new romans',
        color: 'black',
        textAlign: 'left',
        textIndent: 20,
        marginLeft: 4
    },
    tit: {
        fontFamily: 'fantasy',
        marginTop: 10
    },
    h1: {
        textAlign: 'center', 
        textDecoration: 'underline', 
        fontFamily: "tahoma", 
        textDecorationColor: 'gold',
        fontWeight: 'bolder'
    }
})