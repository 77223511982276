import styled from 'styled-components';
import React, { useState } from 'react';
import { Sidebar} from './MenuData'
import SubMenu from './SubMenu';
import { TfiMenu } from "react-icons/tfi";
import logo from "../media/logo.png";

const Nav = styled.div`
    color: white;
    margin: 0px;
    text-align: center;
`


const NavNav = styled.nav`
    background-color: black;
    width: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0': '-100%')};
    transition: 350ms;
    z-index: 10;
`

const NavWrap = styled.div`
    width: 200px;
`


const Menu = () => {

    const [sidebar, setSidebar] = useState(false)

    const showSideBar = () => setSidebar(!sidebar)
    

    return (
        <>
            <Nav>
                <Nav to="#">
                    <TfiMenu size={40} color='rgb(40, 150, 200)' style={{border: '2px solid rgb(40, 150, 200)', padding: 5, borderRadius: '100%', cursor: 'pointer'}}  onClick={showSideBar}/>
                </Nav>
            </Nav>
            <NavNav sidebar={sidebar} style={{width: '100%'}}>
                <NavWrap>
                    
                    <div style={{marginTop: 20,}}>
                        <div style={style.menu}>MENU</div>
                        <p style={style.logo}><img src={logo} height={50} width={50} alt="menu_logo"/></p>
                        <div style={{marginTop: 15, paddingBottom: 130 }} onClick={showSideBar}>
                            {Sidebar.map((item, index)=>{
                                return(
                                    <SubMenu item={item} key={index}></SubMenu>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{color: 'white', textAlign: 'center'}}>
                        <p>&copy; STudy STar</p>
                    </div>
                </NavWrap>
                
            </NavNav>
            
            
        </>
    )

}

export default Menu;


const style = {
    menu: {
        textAlign: 'center', 
        marginLeft: -12,
        fontWeight: 'bolder',
        color: 'rgb(110, 200, 225)',
        fontSize: 36,
        textShadow: "-2px -0px 20px rgb(185, 201, 150)",
        fontFamily: '"Rubik Glitch Pop", system-ui',
        fontStyle: 'normal',
    },
    logo: {
        textAlign: 'center',
        marginLeft: -18,
    }
}