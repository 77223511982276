import React from 'react'

export default function Biochemistry() {
  return (
    <div><br/>
        <h3 style={{textAlign:'center', textDecoration: 'underline'}}>BIOCHEMISTRY</h3>
        <h4 style={{textAlign:'center'}}>Past papers</h4>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Year by Year</h5>
        <div className='container'>
            <div style={styles.year}>2023</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1YvDmXgG9rdi2jQUHGuRTMyoUGqC3nj7h&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1-_-iNthPQYvGx9z9Li2VPPIFZHBD-aNu&export=download' style={styles.testText}>Test 2</a></div>
            </div>
            <div style={styles.year}>2022</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1wahFc4RXfzglcqz5rJ9zrv44NicdKugG&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1akTwtLL_S9ys2ZSB_2q9kjd6JMfJeoPU&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1myLX-aZa_tYuff0_eA1XTh_cSQXb3hlj&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1yxUUbrcRliROWsIQ8c5z45oy14j0wc9n&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2021</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1oxtXftgJQvdxHyLsDESZGWeX7CoAMowQ&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1hidzpE809ecjnbohFUXgmrZ02VqfR0Ts&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=153Gq3SuxUbG64d6vIj8WIgW9diPID9gS&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1ueZZzwAeYk9vGnDjtK3sFkJmpJZ43i21&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1GceNpD8LTDF3LTO87u1imdftbl1EaSjx&export=download' style={styles.testText}>Sessional (With answers)</a></div>
            </div>
            <div style={styles.year}>2020</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1vUg32wBfbBc13xH4s9Bq94UjzOWrRiC8&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1fRxdfeshUpgxl5i8Phq4wFOZsdFZpK-X&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=19svMhAyK_AdvYI9lwhAa2X4U99sK55zJ&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1s466QwNds0OY4N62sgGH0YVuwdyK7HCd&export=download' style={styles.testText}>Sessional Sup</a></div>
            </div>
            <div style={styles.year}>2019</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1pxa9onVZa5mDpqnTh61qxkoU63mWcFIk&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=18bcDOmx1wLmaG37DkUOMfqKnlhPMGvg_&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1i6TlgXH6WkT7KsiFIs8X0chtIf3salFr&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1fvsSP6SXPVhAeQeBtATRqDNt4hGJ-3uN&export=download' style={styles.testText}>Sessional</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1iw3kv9bLs5AkrKbVaOdkekhWynN27NGH&export=download' style={styles.testText}>Sessional (With Answers)</a></div>
            </div>
            <div style={styles.year}>2018</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=18nJVDmClvh33DW1hMIdPgqCb-yaYogGV&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2017</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1du6Ejx3VRDza1g9BRp51TofErx2mNW9F&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=11LgWMfH_szqub8jdMaWxG723NY2oWKdg&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1q3sAifOF-DnTS8y2TwdP3o_PtlVfN2EO&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1we4vjUOi9xIzeq32JvkHUIgLwMU5tZEZ&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={styles.year}>2016</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1GbAbWyhi9-lx_H4XDGGknh2tKB4yJDaz&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1wS80_G0wMc1tvR16ewbPh9qhS8WVNM6M&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=15yXLN0eSic_cW6dWbtQ8RerMl0iCT3CD&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2015</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1gmEy6D91H7xNUaX5o4H4F19iUtdU7Flq&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=14yIS9OMOYhptx8m6ACCs0Fk-xF90tXkI&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1tXo0M3poz9fovXgHrcGnOicFLJMrPAEC&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={styles.year}>2014</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1QzTrIEXPNMOlkwR_CO1macwhvKXRl4Ax&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
        </div>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Topic by Topic</h5>
        <div className='container'>
            {/* <div style={styles.test}><a href='' style={styles.topicText}>Carbohydrates</a></div>
            <div style={styles.test}><a href='' style={styles.topicText}>Vitamins</a></div> */}
        </div>
        <h4 style={{textAlign:'center'}}>Notes</h4>
        <h4 style={{textAlign:'center'}}>Topical Revision Questions</h4>
    </div>
  )
}


const styles = {
    test: {
        padding: 6,
        margin: 8,
        textAlign: 'center'
    },
    testText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(0, 162, 232)',
        color: 'white',
        borderRadius: 2
    },
    topicText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(255, 120, 155)',
    },
    year: {
        fontWeight: 'bolder', 
        fontSize: 25,
        textAlign: 'center'
    }
}