import React, {useState} from 'react';

import { FaCheck } from "react-icons/fa";
import { ImCross } from 'react-icons/im';


export default function IntroductionToPathology(){
    const [ showResults, setShowResults ] = useState(false)
    const clicked = () => setShowResults(true)
    const [ ans1, setAns1 ] = useState('Pick an answer')
    const correctAnswer1 = 'A';
    const [ans2, setAns2] = useState('Pick an answer')
    const correctAnswer2 = 'B';
    const [ans3, setAns3] = useState('Pick an answer')
    const correctAnswer3 = 'A';
    const [ans4, setAns4] = useState('Pick an answer')
    const correctAnswer4 = 'C';
    const [ans5, setAns5] = useState('Pick an answer')
    const correctAnswer5 = 'A';
    const [ans6, setAns6] = useState('Pick an answer')
    const correctAnswer6 = 'C';
    const [ans7, setAns7] = useState('Pick an answer')
    const correctAnswer7 = 'B';
    const [ans8, setAns8] = useState('Pick an answer')
    const correctAnswer8 = 'A';
    const [ans9, setAns9] = useState('Pick an answer')
    const correctAnswer9 = 'A';
    const [ans10, setAns10] = useState('Pick an answer')
    const correctAnswer10 = 'C';
    const [ans11, setAns11] = useState('Pick an answer')
    const correctAnswer11 = 'C';
    const [ans12, setAns12] = useState('Pick an answer')
    const correctAnswer12 = 'A';
    const [ans13, setAns13] = useState('Pick an answer')
    const correctAnswer13 = 'A';
    const [ans14, setAns14] = useState('Pick an answer')
    const correctAnswer14 = 'B';
    const [ans15, setAns15] = useState('Pick an answer')
    const correctAnswer15 = 'C';
    const [ans16, setAns16] = useState('Pick an answer')
    const correctAnswer16 = 'C';
    const [ans17, setAns17] = useState('Pick an answer')
    const correctAnswer17 = 'C';
    const [ans18, setAns18] = useState('Pick an answer')
    const correctAnswer18 = 'B';
    const [ans19, setAns19] = useState('Pick an answer')
    const correctAnswer19 = 'B';
    const [ans20, setAns20] = useState('Pick an answer')
    const correctAnswer20 = 'B';


    let c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13, c14, c15, c16, c17, c18, c19, c20, total


    return (
        
        <div><br/>
            <div className='container'>
                <p style={{fontSize: 20, textAlign: 'center', textDecoration: 'underline'}}>Introduction to Pathology</p>
                <p>
                    <ol>
                        <form>
                            <li>
                                A disease characterized by sudden onset is known as
                            </li>
                                <ol type='a'>
                                    <li>Acute</li>
                                    <li>Chronic</li>
                                    <li>Spontaneous</li>
                                </ol>
                                <select
                                    value={ans1}
                                    onChange={(e) => setAns1(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans1 === correctAnswer1 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c1 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c1 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            

                            <br/>
                            <li>
                                Which of the following is a prognosis of a chronic disease?
                            </li>
                                <ol type='a'>
                                    <li>Death</li>
                                    <li>Progressive destruction of tissue</li>
                                    <li>Resolution</li>
                                </ol>
                                <select
                                    value={ans2}
                                    onChange={(e) => setAns2(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans2 === correctAnswer2 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c2 = 1} mark ]</div>: <div style={{color: 'red'}}> Wrong <ImCross/> [{c2 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which of the following disease causing agents is characterized under chemical agents?
                            </li>
                                <ol type='a'>
                                    <li>Toxins</li>
                                    <li>Bacteria</li>
                                    <li>Radiation</li>
                                </ol>
                                <select
                                    value={ans3}
                                    onChange={(e) => setAns3(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans3 === correctAnswer3 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c3 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c3 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                A group of symptoms and other changes in the body's functions which when put together shows the presence of a disease is known as 
                            </li>
                                <ol type='a'>
                                    <li>Clinical signs</li>
                                    <li>Clinical symptoms</li>
                                    <li>Syndrome</li>
                                </ol>
                                <select
                                    value={ans4}
                                    onChange={(e) => setAns4(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans4 === correctAnswer4 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c4 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c4 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                The gross examination of a cadavar by systematic dissection in order to evaluate any abnormal changes that may be present is known as?
                            </li>
                                <ol type='a'>
                                    <li>Autopsy</li>
                                    <li>Post mortem</li>
                                    <li>Biopsy</li>
                                </ol>
                                <select
                                    value={ans5}
                                    onChange={(e) => setAns5(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans5 === correctAnswer5 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c5 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c5 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                The stiffing of all body muscles after death is known as 
                            </li>
                                <ol type='a'>
                                    <li>Rigor mortis</li>
                                    <li>Livor mortis</li>
                                    <li>Algor mortis</li>
                                </ol>
                                <select
                                    value={ans6}
                                    onChange={(e) => setAns6(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans6 === correctAnswer6 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c6 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c6 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which of the following factors are involved in development of a disease? 
                            </li>
                                <ol type='a'>
                                    <li>Etiology, pathogenesis and functional derangements</li>
                                    <li>Host, disease casuing agent and environmental factors</li>
                                    <li>Size, shape and consistency</li>
                                </ol>
                                <select
                                    value={ans7}
                                    onChange={(e) => setAns7(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans7 === correctAnswer7 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c7 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c7 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                There exists four aspects of disease process that form the core of pathology (Etiology, Pathogenesis, Molecular & Morphological changes and function derangement). Which of the following correctly classifies the classes of aetiological factors.     
                            </li>
                                <ol type='a'>
                                    <li>Intrinsic (genetic), Extrinsic (acquired) and Multifactorial (combination of genetic and acquired)</li>
                                    <li>Intrinsic (acquired), Extrinsic (genetic) and Multifactorial (combination of genetic and acquired)</li>
                                    <li>Intrinsic (genetic), Extrinsic (diarrhoea) and Multifactorial (acquired)</li>
                                </ol>
                                <select
                                    value={ans8}
                                    onChange={(e) => setAns8(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans8 === correctAnswer8 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c8 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c8 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>  
                            <li>
                                Which of the following is an example of functional derangement?  
                            </li>
                                <ol type='a'>
                                    <li>Nausea</li>
                                    <li>Inflammation</li>
                                    <li>Neoplasm</li>
                                </ol>
                                <select
                                    value={ans9}
                                    onChange={(e) => setAns9(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans9 === correctAnswer9 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c9 = 1} mark ]</div> : <div style={{color: 'red'}}>Wrong <ImCross/> [{c9 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                            <br/>
                            <li>
                                
                                    Which of the following is not a method used to study patholgy?
                                </li>
                                <ol type='a'>
                                    <li>Gross examination</li>
                                    <li>Ancillary techniques</li>
                                    <li>Pathogenesis</li>
                                </ol>
                                <select
                                    value={ans10}
                                    onChange={(e) => setAns10(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans10 === correctAnswer10 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c10 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c10 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    Who started the dissection of the human body?
                                </li>
                                <ol type='a'>
                                    <li>Hippocrates</li>
                                    <li>Da Vinci</li>
                                    <li>Vesalius</li>
                                </ol>
                                <select
                                    value={ans11}
                                    onChange={(e) => setAns11(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans11 === correctAnswer11 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c11 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c11 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    The study of disease of the organ systems of the body is known as
                                </li>
                                <ol type='a'>
                                    <li>systematic pathology</li>
                                    <li>Cellular pathology</li>
                                    <li>Clinical pathology</li>
                                </ol>
                                <select
                                    value={ans12}
                                    onChange={(e) => setAns12(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans12 === correctAnswer12 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c12 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c12 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    Determination of the nature of a disease expressed in a concise manner is known as
                                </li>
                                <ol type='a'>
                                    <li>Diagnosis</li>
                                    <li>Prognosis</li>
                                    <li>Autopsy</li>
                                </ol>
                                <select
                                    value={ans13}
                                    onChange={(e) => setAns13(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans13 === correctAnswer13 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c13 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c13 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    Complete recovery is an outcome of
                                </li>
                                <ol type='a'>
                                    <li>Diagnosis</li>
                                    <li>Prognosis</li>
                                    <li>Syndrome</li>
                                </ol>
                                <select
                                    value={ans14}
                                    onChange={(e) => setAns14(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans14 === correctAnswer14 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c14 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c14 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    Which of the following prefixes means rapid
                                </li>
                                <ol type='a'>
                                    <li>Brady</li>
                                    <li>Oligo</li>
                                    <li>Tachy</li>
                                </ol>
                                <select
                                    value={ans15}
                                    onChange={(e) => setAns15(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans15 === correctAnswer15 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c15 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c15 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    Which of the following prefixes means blue
                                </li>
                                <ol type='a'>
                                    <li>Chloro</li>
                                    <li>Leuko</li>
                                    <li>Cyano</li>
                                </ol>
                                <select
                                    value={ans16}
                                    onChange={(e) => setAns16(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans16 === correctAnswer16 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c16 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c16 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    Which of the following prefixes means body
                                </li>
                                <ol type='a'>
                                    <li>Neo</li>
                                    <li>Meta</li>
                                    <li>Homeo</li>
                                </ol>
                                <select
                                    value={ans17}
                                    onChange={(e) => setAns17(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans17 === correctAnswer17 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c17 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c17 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    Which of the following prefixes means skin
                                </li>
                                <ol type='a'>
                                    <li>Cephalo</li>
                                    <li>Derm</li>
                                    <li>Perito</li>
                                </ol>
                                <select
                                    value={ans18}
                                    onChange={(e) => setAns18(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans18 === correctAnswer18 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c18 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c18 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    Which of the following suffixes means deficiency
                                </li>
                                <ol type='a'>
                                    <li>-cytosis</li>
                                    <li>-penia</li>
                                    <li>-ectasis</li>
                                </ol>
                                <select
                                    value={ans19}
                                    onChange={(e) => setAns19(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans19 === correctAnswer19 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c19 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c19 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    Which of the following suffixes means inflammation?
                                </li>
                                <ol type='a'>
                                    <li>-oid</li>
                                    <li>-itis</li>
                                    <li>-osis</li>
                                </ol>
                                <select
                                    value={ans20}
                                    onChange={(e) => setAns20(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans20 === correctAnswer20 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c20 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c20 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>


                        </form>
                        
                    </ol>
                    <div style={{textAlign: 'center'}}>
                        <superButton style={{ 
                            backgroundColor: 'rgb(12, 168, 140)',
                            padding: 8,
                            borderRadius: 4,
                            cursor: 'pointer'
                        }} onClick={clicked}>Show Results</superButton>

                        {
                            showResults? (
                            <div><br/>
                                <p> You got: {c1 + c2 + c3 + c4 + c5 + c6 + c7 + c8 + c9 + c10 + c11 + c12 + c13 + c14 + c15 + c16 + c17 + c18 + c19 + c20}/20 </p>
                                <p> Which is {total = (c1 + c2 + c3 + c4 + c5 + c6 + c7 + c8 + c9 + c10 + c11 + c12 + c13 + c14 + c15 + c16 + c17 + c18 + c19 + c20)/20*100} % </p>
                                {total < 40? <b style={{color: 'rgb(255, 0, 0)', fontSize: 20}}> D: Fail </b>: total < 50 && total >= 40? <b style={{color: 'rgb(252, 63, 7)', fontSize: 22}}> D+: Bare Fail </b>: total < 56 && total >= 50 ? <b style={{color: 'rgb(252, 185, 7)', fontSize: 24}}> C: Bare Pass </b>: total < 62 && total >= 56 ? <b style={{color: 'rgb(240, 253, 6)', fontSize: 26}}> C+: Definite Pass </b>: total < 68 && total >= 62 ? <b style={{color: 'rgb(154, 254, 5)', fontSize: 28}}> B: Very Satisfactory </b>: total < 75 && total >= 68 ? <b style={{color: 'rgb(68, 225, 0)', fontSize: 30}}> B+: Meritorious </b>: total < 86 && total >= 75 ? <b style={{color: 'rgb(0,225,12)', fontSize: 32}}> A: Distinction </b>: total <= 100 && total >= 86 ? <b style={{color: 'rgb(1, 203, 67)', fontSize: 34}}> A+: First Distinction </b>: <div>Error</div>}
                            </div>): null
                        }
                        
                    </div>
                </p>
            </div>
        </div>
    )
}

// const styles = ({
//     ana: {
//         width: '90%'
//     },
//     p: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 14,
//         marginBottom: 14,
//         listStyle: 'none',
        
//     },
//     li: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 20,
//         marginBottom: 20,
        
//     },
//     h1: {
//         textAlign: 'center', 
//         textDecoration: 'underline', 
//         fontFamily: "tahoma", 
//         textDecorationColor: 'rgb(16, 167, 200)',
//         fontWeight: 900,
//     }
// })