import React, {useState} from 'react';

import { FaCheck } from "react-icons/fa";
import { ImCross } from 'react-icons/im';


export default function IntroductionToPhysiology(){
    const [ showResults, setShowResults ] = useState(false)
    const clicked = () => setShowResults(true)
    const [ ans1, setAns1 ] = useState('Pick an answer')
    const correctAnswer1 = 'B';
    const [ans2, setAns2] = useState('Pick an answer')
    const correctAnswer2 = 'A';
    const [ans3, setAns3] = useState('Pick an answer')
    const correctAnswer3 = 'B';
    const [ans4, setAns4] = useState('Pick an answer')
    const correctAnswer4 = 'A';
    const [ans5, setAns5] = useState('Pick an answer')
    const correctAnswer5 = 'B';
    const [ans6, setAns6] = useState('Pick an answer')
    const correctAnswer6 = 'B';
    const [ans7, setAns7] = useState('Pick an answer')
    const correctAnswer7 = 'B';
    const [ans8, setAns8] = useState('Pick an answer')
    const correctAnswer8 = 'A';
    const [ans9, setAns9] = useState('Pick an answer')
    const correctAnswer9 = 'C';
    const [ans10, setAns10] = useState('Pick an answer')
    const correctAnswer10 = 'B';
    const [ans11, setAns11] = useState('Pick an answer')
    const correctAnswer11 = 'A';


    let c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, total


    return (
        
        <div><br/>
            <div className='container'>
                <p style={{fontSize: 20, textAlign: 'center', textDecoration: 'underline'}}>Introduction to Physiology</p>
                <p>
                    <ol>
                        <form>
                            <li>
                                The chemical level of organization of the human body is composed of
                            </li>
                                <ol type='a'>
                                    <li>Cells</li>
                                    <li>Atoms and molecules</li>
                                    <li>Hydrochloric acid</li>
                                </ol>
                                <select
                                    value={ans1}
                                    onChange={(e) => setAns1(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans1 === correctAnswer1 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c1 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c1 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            

                            <br/>
                            <li>
                                The smallest subdivision that is able to carry out all life processes is known as
                            </li>
                                <ol type='a'>
                                    <li>A cell</li>
                                    <li>An atom</li>
                                    <li>A molecule</li>
                                </ol>
                                <select
                                    value={ans2}
                                    onChange={(e) => setAns2(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans2 === correctAnswer2 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c2 = 1} mark ]</div>: <div style={{color: 'red'}}> Wrong <ImCross/> [{c2 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which tissue type is characterized by high speed communication?
                            </li>
                                <ol type='a'>
                                    <li>Muscle</li>
                                    <li>Nervous</li>
                                    <li>Connective</li>
                                </ol>
                                <select
                                    value={ans3}
                                    onChange={(e) => setAns3(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans3 === correctAnswer3 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c3 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c3 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which tissue type generates tension, movement and heat?
                            </li>
                                <ol type='a'>
                                    <li>Muscle</li>
                                    <li>Nervous</li>
                                    <li>Connective</li>
                                </ol>
                                <select
                                    value={ans4}
                                    onChange={(e) => setAns4(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans4 === correctAnswer4 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c4 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c4 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which part of the structural organization of the body enables basic functions needed to maintain overall homeostasis?
                            </li>
                                <ol type='a'>
                                    <li>organ</li>
                                    <li>System</li>
                                    <li>Tissue</li>
                                </ol>
                                <select
                                    value={ans5}
                                    onChange={(e) => setAns5(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans5 === correctAnswer5 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c5 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c5 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                What is the normal temperature and pH of a normal human being?
                            </li>
                                <ol type='a'>
                                    <li>37 <sup>o</sup>C and 7.0</li>
                                    <li>37 <sup>o</sup>C and 7.4</li>
                                    <li>37 <sup>o</sup>C and 7.8</li>
                                </ol>
                                <select
                                    value={ans6}
                                    onChange={(e) => setAns6(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans6 === correctAnswer6 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c6 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c6 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                The maintenance of a constant internal environment is known as
                            </li>
                                <ol type='a'>
                                    <li>Response</li>
                                    <li>Homeostasis</li>
                                    <li>Thermal regulation</li>
                                </ol>
                                <select
                                    value={ans7}
                                    onChange={(e) => setAns7(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans7 === correctAnswer7 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c7 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c7 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                How does the body maintain a constant internal environment?
                            </li>
                                <ol type='a'>
                                    <li>By use of regulatory mechanisms</li>
                                    <li>Responding to stimuli</li>
                                    <li>By reacting to external influences</li>
                                </ol>
                                <select
                                    value={ans8}
                                    onChange={(e) => setAns8(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans8 === correctAnswer8 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c8 = 1} mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [{c8 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>  
                            <li>
                                What does the sensor component do? 
                            </li>
                                <ol type='a'>
                                    <li>receives & integrates information</li>
                                    <li>responds to changes </li>
                                    <li>monitors internal conditions, detects changes</li>
                                </ol>
                                <select
                                    value={ans9}
                                    onChange={(e) => setAns9(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans9 === correctAnswer9 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c9 = 1} mark ]</div> : <div style={{color: 'red'}}>Wrong <ImCross/> [{c9 = 0} marks]</div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                            <br/>
                            <li>
                                
                                    The return of output to the input part of the system is known as
                                </li>
                                <ol type='a'>
                                    <li>Response</li>
                                    <li>Feedback</li>
                                    <li>Physiology</li>
                                </ol>
                                <select
                                    value={ans10}
                                    onChange={(e) => setAns10(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans10 === correctAnswer10 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c10 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c10 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                
                                    The conversion of glucose to glycogen by glucagon is a
                                </li>
                                <ol type='a'>
                                    <li>Negative Feedback</li>
                                    <li>Positive Feedback</li>
                                    <li>Catabolic reaction</li>
                                </ol>
                                <select
                                    value={ans11}
                                    onChange={(e) => setAns11(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans11 === correctAnswer11 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ {c11 = 1} mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ {c11 = 0} marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>


                        </form>
                        
                    </ol>
                    <div style={{textAlign: 'center'}}>
                        <superButton style={{ 
                            backgroundColor: 'rgb(12, 168, 140)',
                            padding: 8,
                            borderRadius: 4,
                            cursor: 'pointer'
                        }} onClick={clicked}>Show Results</superButton>

                        {
                            showResults? (
                            <div><br/>
                                <p> You got: {c1 + c2 + c3 + c4 + c5 + c6 + c7 + c8 + c9 + c10 + c11}/11 </p>
                                <p> Which is {total = (c1 + c2 + c3 + c4 + c5 + c6 + c7 + c8 + c9 + c10 + c11)/11*100} % </p>
                                {total < 40? <b style={{color: 'rgb(255, 0, 0)', fontSize: 20}}> D: Fail </b>: total < 50 && total >= 40? <b style={{color: 'rgb(252, 63, 7)', fontSize: 22}}> D+: Bare Fail </b>: total < 56 && total >= 50 ? <b style={{color: 'rgb(252, 185, 7)', fontSize: 24}}> C: Bare Pass </b>: total < 62 && total >= 56 ? <b style={{color: 'rgb(240, 253, 6)', fontSize: 26}}> C+: Definite Pass </b>: total < 68 && total >= 62 ? <b style={{color: 'rgb(154, 254, 5)', fontSize: 28}}> B: Very Satisfactory </b>: total < 75 && total >= 68 ? <b style={{color: 'rgb(68, 225, 0)', fontSize: 30}}> B+: Meritorious </b>: total < 86 && total >= 75 ? <b style={{color: 'rgb(0,225,12)', fontSize: 32}}> A: Distinction </b>: total <= 100 && total >= 86 ? <b style={{color: 'rgb(1, 203, 67)', fontSize: 34}}> A+: First Distinction </b>: <div>Error</div>}
                            </div>): null
                        }
                        
                    </div>
                </p>
            </div>
        </div>
    )
}

// const styles = ({
//     ana: {
//         width: '90%'
//     },
//     p: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 14,
//         marginBottom: 14,
//         listStyle: 'none',
        
//     },
//     li: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 20,
//         marginBottom: 20,
        
//     },
//     h1: {
//         textAlign: 'center', 
//         textDecoration: 'underline', 
//         fontFamily: "tahoma", 
//         textDecorationColor: 'rgb(16, 167, 200)',
//         fontWeight: 900,
//     }
// })