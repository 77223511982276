import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const STLink = styled(NavLink)`
    text-decoration: none;
    border: 1px dotted black;
    border-radius: 8px;
    padding: 8px;
    margin: 8px;
    font-weight: bolder;
    color: blue;
    font-size: 20px;

    &:hover {
        background: black;
        color: rgb(255,255,255);
        border-right: 3px solid grey;
        border-left: 3px solid grey;
    }
    
`

export default function Advanced(){
    
    return (
        
        <div><br/>
            <h1 style={styles.h1}>CBU NQ DATA</h1>
            <div className='body'>
                    <div>
                        <p style={{textAlign: 'center'}}>Physics is very easy to understand, just pay attention to details - Mr Muma</p>
                        <p style={styles.li}><STLink to="/tutorials/BI110">BI 110</STLink></p>
                        <p style={styles.li}><STLink to="/tutorials/CH110">CH 110</STLink></p>
                        <p style={styles.li}><STLink to="/tutorials/PH110">PH 110</STLink></p>
                        <p style={styles.li}><STLink to="/tutorials/MA110">MA 110</STLink></p>
                        <p style={styles.li}><STLink to="/tutorials/CS110">CS 110</STLink></p>
                        {/* <p style={styles.li}><STLink to="/tutorials/LA111">Physics</STLink></p> */}
                    </div>
            </div>
        </div>
    )
}

const styles = ({
    p: {
        fontFamily: 'arial',
        textAlign: 'center',
        marginTop: 14,
        marginBottom: 14,
        listStyle: 'none',
        
    },
    li: {
        fontFamily: 'arial',
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20,
        
    },
    h1: {
        textAlign: 'center', 
        textDecoration: 'underline', 
        fontFamily: "tahoma", 
        textDecorationColor: 'rgb(16, 167, 200)',
        fontWeight: 'bolder'
    }
})